// Only one item MUST have the "default: true" key

module.exports = {
  ja: {
    default: true,
    path: `ja`,
    siteLanguage: `ja`,
    locale: `ja-jp`,
    ogLanguage: `ja_jp`,
    dateFormat: `YYYY/MM/DD`,
    title: `日本語 (Japanese)`,
  },
  en: {
    path: `en`,
    siteLanguage: `en`,
    locale: `en-US`,
    ogLanguage: `en_US`,
    dateFormat: `MMM DD, YYYY`,
    title: `English`,
  },
  de: {
    path: `de`,
    siteLanguage: `de`,
    locale: `de-DE`,
    ogLanguage: `de_DE`,
    dateFormat: `DD/MM/YYYY`,
    title: `Deutsch`,
  },
  fr: {
    path: `fr`,
    siteLanguage: `fr`,
    locale: `fr-FR`,
    ogLanguage: `fr_FR`,
    dateFormat: `DD/MM/YYYY`,
    title: `Français`,
  },
  es: {
    path: `es`,
    siteLanguage: `es`,
    locale: `es-ES`,
    ogLanguage: `es_ES`,
    dateFormat: `DD/MM/YYYY`,
    title: `Español`,
  },
  pt: {
    path: `pt`,
    siteLanguage: `pt`,
    locale: `pt-PT`,
    ogLanguage: `pt_PT`,
    dateFormat: `DD/MM/YYYY`,
    title: `Português`,
  },
  it: {
    path: `it`,
    siteLanguage: `it`,
    locale: `it-IT`,
    ogLanguage: `it_IT`,
    dateFormat: `DD/MM/YYYY`,
    title: `Italiano`,
  },
  nl: {
    path: `nl`,
    siteLanguage: `nl`,
    locale: `nl-NL`,
    ogLanguage: `nl_NL`,
    dateFormat: `DD/MM/YYYY`,
    title: `Nederlands`,
  },
  pl: {
    path: `pl`,
    siteLanguage: `pl`,
    locale: `pl-PL`,
    ogLanguage: `pl_PL`,
    dateFormat: `DD/MM/YYYY`,
    title: `Polski`,
  },
  ru: {
    path: `ru`,
    siteLanguage: `ru`,
    locale: `ru-RU`,
    ogLanguage: `ru_RU`,
    dateFormat: `DD/MM/YYYY`,
    title: `Русский (Russe)`,
  },
  zh: {
    path: `zh`,
    siteLanguage: `zh`,
    locale: `zh-CN`,
    ogLanguage: `zh_CN`,
    dateFormat: `YYYY/MM/DD`,
    title: `简体中文 (Simplified Chinese)`,
  },
  vi: {
    path: `vi`,
    siteLanguage: `vi`,
    locale: `vi-VN`,
    ogLanguage: `vi_VN`,
    dateFormat: `DD/MM/YYYY`,
    title: `Tiếng Việt (Vietnamese)`,
  },
  ko: {
    disable: true,
    path: `ko`,
    siteLanguage: `ko`,
    locale: `ko-KR`,
    ogLanguage: `ko_KR`,
    dateFormat: `DD/MM/YYYY`,
    title: `조선어 (Korean)`,
  },
  ar: {
    disable: true,
    path: `ar`,
    siteLanguage: `ar`,
    locale: `ar-AE`,
    ogLanguage: `ar_AE`,
    dateFormat: `DD/MM/YYYY`,
    title: `العربية (Arabic)`,
  },
  id: {
    disable: true,
    path: `id`,
    siteLanguage: `id`,
    locale: `id-ID`,
    ogLanguage: `id_ID`,
    dateFormat: `DD/MM/YYYY`,
    title: `bahasa Indonesia (Indonesian)`,
  },
}